import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Nikita Vasyliev" subtitle="PA to Director, Alumni and Friends Association Liaison Officer " mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "570px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8b2d4d43364c2dfe2aff04473175a926/882a4/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQL/xAAXAQEBAQEAAAAAAAAAAAAAAAABAwAC/9oADAMBAAIQAxAAAAGLvVSukAdD1FZqLNKAn//EAB0QAAEEAgMAAAAAAAAAAAAAAAIAAQMRBBATIzL/2gAIAQEAAQUC2PrKYeJQxMQuLEJ49FE/Xatf/8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQMBAT8BI//EABkRAQACAwAAAAAAAAAAAAAAAAEAERASMf/aAAgBAgEBPwFaaw9mzP/EABwQAAIBBQEAAAAAAAAAAAAAAAABEAIREiExIv/aAAgBAQAGPwKULi3GVRi1o88KbT//xAAcEAEAAgMBAQEAAAAAAAAAAAABABEQITFhQVH/2gAIAQEAAT8hiJ0xXfyzsEwNxRjvj8INteo30+pfQoNYahc//9oADAMBAAIAAwAAABDDyI//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAEDAQE/ECheDDTf/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQAQEVH/2gAIAQIBAT8QxOqS6hL/xAAdEAEAAgIDAQEAAAAAAAAAAAABABEhQRAxUWHR/9oACAEBAAE/EO4OKA9car2tKvcoOkCtcZ/eFtiVLqKN/YWMs0s36MzSqXsj5CYiGr6VcFTK5C0vE//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/8b2d4d43364c2dfe2aff04473175a926/882a4/01.jpg",
              "srcSet": ["/static/8b2d4d43364c2dfe2aff04473175a926/f93b5/01.jpg 300w", "/static/8b2d4d43364c2dfe2aff04473175a926/882a4/01.jpg 570w"],
              "sizes": "(max-width: 570px) 100vw, 570px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`Sharing Success, Caring for Each Other`}</p>
    </blockquote>
    <p>{`2019 / 2020 was a remarkable year in the history of the PSI Alumni Association.`}</p>
    <h3 {...{
      "id": "alumni-reunions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alumni-reunions",
        "aria-label": "alumni reunions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alumni Reunions`}</h3>
    <p>{`On 18 January, we held our second annual reunion in London in the modern Lighterman Pub. PSI School Director Rachel Caldwell met with PSI Graduates who shared their current stories and recent successes in both their professional and personal lives.`}</p>
    <p>{`Shortly afterwards, the Director travelled to New York, where on 31 January, we hosted our first-ever Alumni Reunion Dinner. It was a wonderful evening at the Ribalta Restaurant, close to one of the most elegant streets in the world, 5th Avenue. Our current Board Chair, Alex Munteanu, also joined us. It was a fun occasion to reconnect, as well as to plan for future interactions.`}</p>
    <h3 {...{
      "id": "improvements--progress",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#improvements--progress",
        "aria-label": "improvements  progress permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improvements & Progress`}</h3>
    <p>{`We are thankful to our alumni for their continuous support and always happy to support them.`}</p>
    <p>{`During this year we successfully relaunched our social media pages, which by now has in total 419 subscribers, both on Facebook and Instagram.`}</p>
    <p>{`One of our achievements is an updated and improved database, and the process of data collection. That is an essential piece of our communication that helps us in many ways of sharing news and keeping in touch with our alumni.`}</p>
    <p>{`This year we also established a strong connection with the International Baccalaureate Organisation, IB Global Centre in Washington, DC. Our collaboration will help us to spread the word about PSI and our Alumni particularly, by  promoting and sharing the news from them and about our school.`}</p>
    <h3 {...{
      "id": "virtual-alumni-reunion-in-kyiv",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#virtual-alumni-reunion-in-kyiv",
        "aria-label": "virtual alumni reunion in kyiv permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Virtual Alumni Reunion in Kyiv`}</h3>
    <p>{`Despite all of the uncertainties caused by COVID-19 pandemic, we are keen to support our alumni.`}</p>
    <p>{`Due to pandemic we postponed our alumni reunion at PSI. However, this did not stop us from facilitating our reunion virtually.`}</p>
    <p>{`We are thankful to 15 of our alumni and staff & faculty members who joined us on this occasion. It was a special event in many ways. Ms Rachel Caldwell shared the most recent and important updates from the school, along with PSI's response to the pandemic.`}</p>
    <p>{`It was great to meet and reminisce with some of our alumni. Again, we enhanced our connection and hope that this event brought more light to the lives of members of our community.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      